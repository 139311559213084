import { bootstrap } from '@trident/extension-client';

bootstrap({
  initializeUI: (_params) =>
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "extensionUi" */
      './index.ui-wrapper'
    ).then(({ initialize }) => initialize(_params)),
  initializeWorker: (_params) =>
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "extensionWorker" */
      './index.worker'
    ).then(({ initialize }) => initialize(_params)),
});
